import { template as template_6fe3326d5c2d4361a4fcb5de8caddd53 } from "@ember/template-compiler";
const SidebarSectionMessage = template_6fe3326d5c2d4361a4fcb5de8caddd53(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
